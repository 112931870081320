import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Communiqué de presse -  La Consigne GreenGo - Paris, le 10 Juin 2021`}</strong></p>
    <p><strong parentName="p">{`Après avoir confirmé sa place de leader en restauration collective, La Consigne GreenGo, 1er réseau de restos sans emballage jetable, se lance dans le 10ème arrondissement de Paris.`}</strong>{` `}</p>
    <p><strong parentName="p">{`Avec l'accompagnement financier de la Ville de Paris dans le cadre de son soutien aux solutions de déploiement de consigne pour réemploi, de l’ADEME et Citeo, La Consigne GreenGo lance son service digital et clé en main dans une cinquantaine de restaurants et dans une vingtaine de Franprix.`}</strong>{` `}</p>
    <p>{`En effet, suite à l’Appel à Manifestation d’Intérêt lancé mi-2019, Citeo et l’ADEME ont sélectionné La Consigne GreenGo et son projet Paris s’emballe pour accompagner les restaurants dans leur transition du jetable vers un modèle d’emballages réemployables pour leur vente à emporter. `}</p>
    <p>{`Paris s’emballe permet de contribuer positivement aux objectifs fixés par la loi AGEC (loi anti-gaspillage et pour une économie circulaire) et son inauguration se tiendra à la Mairie du 10ème arrondissement, le mardi 29 juin prochain de 12h à 14h. `}</p>
    <p><strong parentName="p">{`Alexandra Cordebard, Maire du 10ème arrondissement et Florentin Letissier, Adjoint à la Maire de Paris en charge de l’Économie Sociale et Solidaire, de l’économie circulaire et de la trajectoire zéro déchet nous feront le plaisir d’inaugurer ce projet.`}</strong></p>
    <p>{`Aujourd’hui, c’est plus de 25 emballages par seconde qui sont consommés pour la vente à emporter chaque année en France. Et pourtant la plupart de ces emballages sont peu ou pas recyclés.`}</p>
    <p>{`Face à l'explosion grandissante des livraisons de repas à domicile (+25% depuis la crise sanitaire) et à la réglementation qui pousse à la réduction des emballages à usage unique, le réemploi semble ainsi être la solution durable pour l’ensemble des acteurs de la restauration. `}</p>
    <p>{`L’objectif ? Créer le plus grand réseau de points de collecte d’emballages réutilisables pour faciliter l’expérience consommateur et accompagner simplement les restaurateurs dans cette transition. `}</p>
    <p>{`L’ambition ? Étendre ce modèle à l’ensemble des arrondissements de Paris et au niveau national.`}</p>
    <p><strong parentName="p">{`Concrètement, comment ça marche ?`}</strong>{` `}</p>
    <p>{`C’est simple : téléchargez l’application La Consigne GreenGo, découvrez l’ensemble des partenaires du réseau, empruntez gratuitement un emballage consigné dans un commerce partenaire et rapportez-le dans un point de collecte sous 14 jours.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.france24.com/fr/%C3%A9missions/%C3%A9l%C3%A9ment-terre/20210521-livraisons-de-repas-on-passe-%C3%A0-la-consigne"
      }}>{`Plus d’informations dans ce reportage de France24`}</a>{` `}</p>
    <p>{`Un grand merci aux partenaires Paris s’emballe :`}</p>
    <ul>
      <li parentName="ul">{`Le `}<a parentName="li" {...{
          "href": "https://www.paris.fr/"
        }}>{`Ville de Paris`}</a>{`, l`}<a parentName="li" {...{
          "href": "https://mairie10.paris.fr/"
        }}>{`a Mairie du 10ème`}</a>{`, `}<a parentName="li" {...{
          "href": "https://www.ademe.fr/"
        }}>{`ADEME`}</a>{`, `}<a parentName="li" {...{
          "href": "https://www.citeo.com/"
        }}>{`Citeo qui`}</a>{` accompagnent financièrement la mise en place du projet`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.franprix.fr/"
        }}>{`Franprix`}</a>{`, qui équipe ses magasins du 10ème arrondissement de la solution de Consigne GreenGo incluant leurs bornes automatiques appelées "collecteurs"`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ecotable.fr/fr"
        }}>{`Ecotable`}</a>{` qui accompagne, forme et valorise les restaurants aux pratiques écoresponsables, évaluera l'impact environnemental et économique d'un passage à  la consigne au niveau d'un quartier`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.laconsignegreengo.com/"
        }}>{`La Consigne GreenGo,`}</a>{` premier réseau de restos sans emballage jetable, qui déploie son service digital et clé en main d'emballages réutilisables et consignés`}</li>
    </ul>
    <p><strong parentName="p">{`A propos de La Consigne GreenGo`}</strong>{` `}</p>
    <p>{`Fondée en 2018 par Yasmine Dahmane (CentraleSupélec, McKinsey) et Lucas Graffan (EM Lyon, Procter & Gamble), la Consigne GreenGo a déjà séduit plus de 50 clients Grands Compte du CAC 40 et a permis d’éviter plus de 80 000 emballages, soit 1,1 tonnes de déchets. Afin d’asseoir sa position de leader en restauration collective et forte d’ambition pour la restauration indépendante, l’équipe est passée de 5 à 16 collaborateurs en seulement un an. `}</p>
    <p>{`Plus d’informations sur `}<a parentName="p" {...{
        "href": "http://www.laconsignegreengo.com"
      }}>{`www.laconsignegreengo.com`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/drive/folders/19JYrP8QVLPbM5NndAttw9Sh6BgnkGvsA?usp=sharing"
      }}>{`Kit presse disponible`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.140625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "greengo projet mairie 10 final bleu",
          "title": "greengo projet mairie 10 final bleu",
          "src": "/static/0ceb49405c80749e6a4814b039b09128/f1720/greengo_projet-mairie-10_final_bleu.png",
          "srcSet": ["/static/0ceb49405c80749e6a4814b039b09128/85b06/greengo_projet-mairie-10_final_bleu.png 256w", "/static/0ceb49405c80749e6a4814b039b09128/bc282/greengo_projet-mairie-10_final_bleu.png 512w", "/static/0ceb49405c80749e6a4814b039b09128/f1720/greengo_projet-mairie-10_final_bleu.png 1024w", "/static/0ceb49405c80749e6a4814b039b09128/4e07d/greengo_projet-mairie-10_final_bleu.png 1044w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      